import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Image from "gatsby-plugin-sanity-image";
import Categories from "../components/Categories";
import PaintingList from "../components/PaintingList";
import Nav from "../components/Nav";
import Backdrop from "../components/Backdrop/Backdrop";
import SEO from "../components/SEO";
import GallerHeading from "../components/GalleryHeading";

const TopGrid = styled.div`
  display: grid;
  width: 80%;

  grid-template-columns: 0.3fr 1fr;
  margin: auto;
  margin-top: 20px;
  @media (max-width: 800px) {
    grid-template-columns: 0.5fr 1fr;
    width: 90%;
  }
  @media (max-width: 550px) {
    width: 100%;
    grid-template-columns: 0.9fr 1fr;
  }
`;
const NavStyle = styled.div`
  min-width: 120px;
`;
const GalleryTitle = styled.h3`
  text-align: center;
  padding-top: 20px;
  color: black;
  text-decoration: underline;
  font-weight: bold;
`;
const TitleCard = styled.div`
  color: var(--grey);
  margin: 20px;
  @media (max-width: 450px) {
    margin: 5px;
  }
`;
const Title = styled.h2`
  font-style: italic;
  font-weight: bold;
  padding: 10px;
  background-color: #7a0301;
  margin: 10px;
  @media (max-width: 450px) {
    margin: 5px;
    padding: 2px;
  }
`;
const SubTitle = styled.h5`
  font-style: italic;
  font-size: large;
  margin: 10px;
  padding-bottom: 20px;
  padding-left: 25px;
`;

const CategoryStyle = styled.div`
  grid-row: 4;
`;
const PaintingsStyle = styled.div`
  grid-row: 5;
`;

export default function Gallery({ data }) {
  // initial category set to New work
  const [category, setCategory] = useState("New Work");
  // is painting from gallery open?
  const [isOpen, setIsOpen] = useState(false);
  // first time page open
  const [firstVisit, setFirstVisit] = useState(true);

  // if firstVisit tab is closed set first visit to false
  // if painting clicked on set to true
  const closeFirstVisit = (e) => {
    setFirstVisit((prevState) => !prevState);
  };

  // if painting clicked on set to true
  const clickPainting = (e) => {
    setIsOpen((prevState) => !prevState);
  };

  // sort gallery
  // function passed to categories component
  const clickCategory = (e) => {
    e.preventDefault();
    if (!e.target.id) {
      setCategory(e.target.parentElement.parentElement.parentElement.id);
    } else {
      setCategory(e.target.id);
    }
  };
  console.log(data)
  const paintings = data.allSanityPaintings.nodes;
  const categoriesList = [];
  const categoryImages = [];
  const sortedPaintings = [];
  paintings.forEach((element) => {
    if (element.categories[0]) {
      // get list of categories
      if (!categoriesList.includes(element.categories[0].title)) {
        categoriesList.push(element.categories[0].title);
        categoryImages.push(element.categories[0].categoryImage);
      }

      if (element.categories[0].title === category) {
        sortedPaintings.push(element);
      }
    }
  });

  let backdrop;
  if (isOpen) {
    backdrop = <Backdrop />;
  }
  let galleryHeading;
  if (firstVisit) {
    galleryHeading = (
      <GallerHeading
        image={paintings[6].image}
        closeFirstVisit={closeFirstVisit}
      />
    );
  }
  return (
    <>
      <SEO title="Gallery" />
      {backdrop}
      {galleryHeading}
      <TopGrid>
        <NavStyle>
          <Nav />
        </NavStyle>
        <Title>
          <TitleCard>
            <Title>Galleries</Title>
            <SubTitle>
              Click a category below to sort my work. The gallery is currently
              sorted by {category}. Click an individual painting to see details.
            </SubTitle>
          </TitleCard>
        </Title>
      </TopGrid>
      <CategoryStyle>
        <Categories
          categoryList={categoriesList}
          categoryImages={categoryImages}
          clickCategory={clickCategory}
        />
      </CategoryStyle>
      <PaintingsStyle>
        <GalleryTitle>{category}</GalleryTitle>
        <PaintingList
          paintings={sortedPaintings}
          clickPainting={clickPainting}
          isOpen={isOpen}
        />
      </PaintingsStyle>
    </>
  );
}

export const query = graphql`
  query($skip: Int = 0, $pageSize: Int = 100) {
    allSanityPaintings(limit: $pageSize, skip: $skip) {
      totalCount
      nodes {
        title
        description
        size
        slug {
          current
        }
        image {
          ...ImageWithPreview
        }
        categories {
          title
          categoryImage {
            image {
              ...ImageWithPreview
            }
          }
        }
      }
    }
  }
`;
