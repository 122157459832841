import React, { useState } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Image from "gatsby-plugin-sanity-image";

const GalleryGrid = styled.div`
  padding: 6vh 8vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
 
`;

export default function PaintingList({ paintings, isOpen, clickPainting }) {
  
  return (
    <GalleryGrid>
      {paintings.map((painting) => (
        <Painting
          title={painting.title}
          size={painting.size}
          slug={painting.slug}
          image={painting.image}
          key={painting.slug.current}
          isOpen={isOpen}
          clickPainting={clickPainting}
        />
      ))}
    </GalleryGrid>
  );
}

const Tile = styled.div`
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  width: 28vw;
  img {
    width: 100%;
    transition: transform 2ms ease;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const TextStyles = styled.div`
  
  color: white;
  top: 1%;
  position: fixed !important;
  z-index: 6;
`;
const ImageOpen = styled(Image)`
  z-index: 5;
  position: fixed !important;
  top: 15%;
  width: 600px;
  cursor: pointer;
`;

function Painting({ title, size, slug, image, isOpen, clickPainting }) {
  const [open, setOpen] = useState(false);
  const [paintingId, setPaintingId] = useState("");

  const clickHandler = (e) => {
    e.preventDefault();
    
    if (open === false) {
      clickPainting();
      setPaintingId(e.target.id);
      setOpen(true);
    } else {
      clickPainting();
      setPaintingId("");
      setOpen(false);
    }
  };

  if (paintingId) {
    <>
      <ImageOpen {...image} alt={slug} id={title} />
      <TextStyles>
        <h1>{title}</h1>
        <h2>{size}</h2>
      </TextStyles>
    </>;
  }

  return (
    <>
      {paintingId ? (
        <>
          <ImageOpen {...image} alt={slug} id={title} onClick={clickHandler}/>
          <TextStyles>
            <h1>{title}</h1>
            <h2>{size}</h2>
          </TextStyles>
        </>
      ) : (
        <></>
      )}
      <Tile onClick={clickHandler} id={slug} title={title}>
        <Image {...image} alt={slug} id={title} />
      </Tile>
    </>
  );
}
