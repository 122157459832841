import React from "react";
import Backdrop from "./Backdrop/Backdrop";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";

const Wrapper = styled.div``;
const Panel = styled.div`
  background-color: #4d0100;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 30;
  
`;
const StyledGrid = styled.div`
  text-align: center;
  padding: 20px;
`;
const Description = styled.p`
  color: var(--grey);
  text-align: center;
  font-size: 15px;
  @media (max-width: 800px) {
   display:none;
  }
`;
const ImageStyle = styled(Image)`
  margin: auto;
  width: 40%;
  @media (max-width: 800px) {
   width: 100%;
  }
  
`;
const BottomText = styled.h3`
  color: var(--grey);
  text-align: center;
`;

export default function GallerHeading({ closeFirstVisit, image }) {
  return (
    <Wrapper onClick={closeFirstVisit}>
      <Panel>
        <StyledGrid>
        

        <ImageStyle {...image} />
          <Description>
            These galleries show works inspired mostly by my interest in areas
            outside the normal discourse that takes place within the limiting
            parameters of the contemporary art world - meditation and spiritual
            discourse on the nature of conciousness, speculative scientific
            theory (the woo woo of it, so to speak), and the rather befuddling
            and provocative examples of contemporary and historical fugures
            who's lives challenge our assumptions. Also, of course, the rather
            befuddling and provocative experiences that pop up from time to time
            in my own life. To quote an inscription in Latin on the tomb of Carl
            Jung, "vocatus atgue non vocatus, deus aderit"; or in contemporary
            English, "called or not called, the gods rise".
          </Description>
          
        <BottomText> Click anywhere to continue!</BottomText>
        </StyledGrid>
      </Panel>
      <Backdrop />
    </Wrapper>
  );
}
