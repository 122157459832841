import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Image from "gatsby-plugin-sanity-image";
const Wrapper = styled.div`
`;
const CategoryButton = styled.div`
  background-color: grey;
  padding: 6px;
  width: 40px;
  border-radius: 20%;
  text-align:center; 
`;
const HeaderWrapper =styled.div`
  display: flex;
  margin: auto;
  max-width: 310px;
  border-radius: 10%;
  background-color: #7a0301;
  border:2px solid black;
  padding: 20px;
  color: var(--grey);
  &:hover {
    background-color: #570100;
  }
`;
const ButtonHeader = styled.div`
  margin: auto;
`;
const ToggleLine = styled.div`
  background-color: #444;
  border-radius: 20%;
  margin: 5px auto;
  height: 2px;
  width: 60%;
`
const CategoryGrid = styled.div`
  display: grid;
  grid-gap: 5px;
  transition: width 2s, height 4s;
  transition-timing-function: ease-in;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 20px auto;
  max-width: 1200px;

  @media (max-width: 1150px) {
    width: 650px;
    grid-template-columns: 155px 155px 155px 155px;
    grid-template-rows: 1fr 1fr;
  }
  @media (max-width: 675px) {
    width: 320px;
    grid-template-columns: 155px 155px;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
`;
const CategoryItem = styled.div`
  margin: 15px;
  padding: 10px;
  background-color: white;
`;
const OverLay = styled.div`
  background-color: #7a0301;
  width: 155px;
  transition: 0.4s;
  box-shadow: -10px 26px 8px rgba(0, 0, 0, 0.25);
  z-index: 4;
  &:hover {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    z-index: 4;
    background-color: #440101;
  }
`;
const Item = styled.div`
  text-align: center;
  font-size: 16px;
  color: black;
  height: 40px;
`;
const ImageStyle = styled(Image)`
  height: 90px;
  min-width: 90px;
`;

const Categories = ({ categoryList, categoryImages, clickCategory }) => {
  const [sortButton, setSortButton] = useState(false);
  useEffect(() => {
    setSortButton(true);
  }, []);

  useEffect(() => { 
    if(!sortButton){
      setSortButton(true)
    }
  }, [clickCategory])
  const toggle = (e) => {
    e.preventDefault()
    setSortButton( isOpen => !isOpen)
  }
  return (
    <Wrapper>
      <HeaderWrapper onClick={toggle}>
      <CategoryButton>
      <ToggleLine />
        <ToggleLine />
        <ToggleLine /></CategoryButton>
        {sortButton ? (<ButtonHeader>Click here to Open galleries</ButtonHeader>) : (<ButtonHeader>Click here to close</ButtonHeader>) }
        
        </HeaderWrapper>
        { sortButton ? (null) : (
          <CategoryGrid>
        {categoryList.map((item, i) => (
          <OverLay id={item} key={`${item}-category`} onClick={clickCategory}>
            <CategoryItem id={item}>
              <Item id={item}>{item}</Item>
              <ImageStyle id={item} {...categoryImages[i][0].image} />
            </CategoryItem>
          </OverLay>
        ))}
      </CategoryGrid>) }
      
    </Wrapper>
  );
};

export default Categories;
